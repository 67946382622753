import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'

import config from '$src/config'
import imgLogo from './logo.svg'

import styles from './Content.module.scss'

const Content = ({ image, text }) => {
  return (
    <>
      <Image fluid={image} className={styles.mobileImage} alt={config.title} />

      <div className={styles.content}>
        <div className="wrapper-m">
          <div className={styles.logo}>
            <img src={imgLogo} alt="logo" />
          </div>
          <div className={styles.text}>{text}</div>
          <div className={styles.spacer} />
        </div>
      </div>
    </>
  )
}

Content.propTypes = {
  image: PropTypes.any.isRequired,
  text: PropTypes.any.isRequired,
}

export default Content
