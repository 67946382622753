import React from 'react'
import rehypeReact from 'rehype-react'
import BackgroundImage from 'gatsby-background-image'
import { graphql } from 'gatsby'

import MdLink from '$components/utility/MdLink'
import Content from '$components/pageIndex/Content'

// prettier-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    a: MdLink,
    h2: (props) => <h2 {...props} className="t-heading-l">{props.children}</h2>,
    h3: (props) => <h3 {...props} className="t-heading-m">{props.children}</h3>,
  }
}).Compiler

const Index = ({ data: { index } }) => (
  <BackgroundImage
    critical
    className="contentBackgroundImage"
    fluid={index.frontmatter.background.childImageSharp.fluid}
    style={{ height: '100%' }}
  >
    <Content
      image={index.frontmatter.background.childImageSharp.fluid}
      text={renderAst(index.htmlAst)}
    />
  </BackgroundImage>
)

export default Index

export const query = graphql`
  query Index($locale: String!) {
    index: markdownRemark(
      frontmatter: { title: { eq: "index" } }
      fields: { locale: { eq: $locale } }
    ) {
      html
      htmlAst
      frontmatter {
        title
        background {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
